.width-100 {
  width: 100%;
}

.scroll-bar-active-intv {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  height: 100vh;
  scrollbar-width: thin;
}

.top-section-active-intv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;

  button {
    top: 13.24px;
    left: 1278.98px;
    gap: 0px;
  }
}

.title-left-active-intv {
  font-size: 18px;
  font-weight: bold;
  color: #15649c;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  flex-grow: 1;
  padding-left: 10px;
}

.profile-button-right-active-intv {
  display: flex;
  width: 131.44px;
  height: 36.51px;
  top: 13.24px;
  left: 1278.98px;
  gap: 0px;
  border-radius: 38px;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  color: #5e7ebd;
  background: linear-gradient(90deg, rgba(213, 223, 242, 0.5) 0%, rgba(206, 196, 232, 0.5) 100%);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-active-intv {
  padding-right: 5px;
}

.filters-active-intv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 45px 10px 35px;
}

.filters-left-active-intv {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  /* for responsiveness */
  background-color: #f2f2f2;

  input,
  select {
    width: 45%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #858484;
    border: 0.1px solid #d0cfcf;
    background-color: #ededed;
    border-radius: 10px;
  }
}

.filters-right-active-intv {
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #f2f2f2;

  div {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #858484;
    border: 0.1px solid #d0cfcf;
    background-color: #ededed;
    border-radius: 10px;
    padding: 5px 10px;
  }

  button {
    width: 30px;
    height: 30px;
    border-radius: 5px 5px 5px 5px;
    background: #434343;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mainContent-active-intv {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.table-container-active-intv {
  width: 100%;
  overflow-x: auto;
  /* padding: 20px; */
}

.dynamic-table-data-active-intv {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  text-align: left;
  background-color: #f2f2f2;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 10px;
  border-spacing: 30px;
  border-collapse: separate;
  border-spacing: 0 15px;

  select {
    border-radius: 10px;
  }

  .alignCenter-intv {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.dynamic-table-data-active-intv thead th {
  background-color: #ededed;
  padding: 10px;
  font-weight: bold;
  color: #4a4a4a;
  text-align: left;
  /* display: flex;
    align-items: center; */
}

.dynamic-table-data-active-intv tbody tr {
  padding: 10px;

  td:first-child {
    border-radius: 13px 0 0px 13px;
  }

  td:last-child {
    border-radius: 0 13px 13px 0;
  }
}

.dynamic-table-data-active-intv tbody td {
  background-color: #ffffff;
  padding: 10px;
  /* border-bottom: 10px solid #f7f7f7; */
  text-align: left;
  /* border-radius: 25px; */
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4a4a4a;

  /* Rounded corners for cells */
}

.dynamic-table-data-active-intv tbody tr {
  border-bottom: 10px solid #e0e0e0;
}

.dynamic-table-data-active-intv tbody tr:hover {
  background-color: #e0e0e0;
  transition: background-color 0.2s ease;
}

.dynamic-table-data-active-intv td div {
  text-overflow: ellipsis;
  overflow: clip;
}

.client-name-intv {
  width: 300px !important;
  /* background-color: red !important */
}

.vendor-name-intv {
  width: 500px !important;
  /* background-color: red !important */
}

.rate-class-intv {
  width: 150px !important;
  /* background-color: red !important */
}

.name {
  display: flex;
  align-items: center;
}

.company-data-active-intv {
  width: 156.92px;
  height: 29.21px;
  top: 180.92px;
  left: 895.4px;
  gap: 0px;
  border-radius: 10px 0px 0px 0px;
  border: 0.1px 0px 0px 0px;
  border: 0.1px solid #d0cfcf;
  background-color: #ededed;
}

.button-icon-intv {
  border: none;
  outline: none;
  background-color: #ffffff;
}

.table-container-active-intv .navigation .text {
  font-size: 14px;
}
.table-container-active-intv .navigation {
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}
.table-container-active-intv .nav {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-container-active-intv .left.nav svg {
  rotate: 180deg;
}
