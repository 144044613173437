.loader{
    border: solid 4px;
    border-top: solid 4px transparent !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: spin 1.5s ease infinite;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }