.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;

    button {
        top: 13.24px;
        left: 1278.98px;
        gap: 0px;
    }
}
.recruiter-body{
    flex-grow: 1;
    width: auto;
}

.organization-options{
    width: 100%;
    background-color: #f8f9fa;
    margin-bottom: 10px;
    border: solid #e0e0e0;
    border-width: 0px 0px 1px 0px;
}

.organization-options-list{
    list-style-type: none;
    margin-left: 15px;
    padding: 10px;
    li{
        border: solid #8e8e8e;
        border-width: 0.5px;
        padding: 10px;
        cursor: pointer;
        font-weight: 600;
        color: #8e8e8e;
        border-radius: 5px;
    }

    li:hover{
        border-color: #15649c;
        color: #15649c;
    }
}

.option-active{
    border-color: #15649c !important;
    color: #15649c !important;
}
.table-container {
    /* width: 100%;
    overflow-x: auto; */
    /* padding: 20px; */
}

.search-input{
    padding: 5px 3px 5px 3px;
    /* width: 50% !important; */
}
.dynamic-table-data {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
    text-align: left;
    background-color: #F2F2F2;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    /* line-height: 23px; */
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* padding: 10px; */
    border-spacing: 30px;
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-top: -12px;

    select {
        border-radius: 10px;
    }

    .alignCenter {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}


.dynamic-table-data thead th {
    background-color: #EDEDED;
    /* padding: 10px; */
    font-weight: bold;
    color: #4A4A4A;
    text-align: left;
    /* display: flex;
    align-items: center; */
}

.dynamic-table-data tbody tr {
    padding: 10px;

    td:first-child {
        border-radius: 13px 0 0px 13px;

    }

    td:last-child {
        border-radius: 0 13px 13px 0;

    }

}


.dynamic-table-data tbody td {
    background-color: #FFFFFF;
    padding: 10px;
    /* border-bottom: 10px solid #f7f7f7; */
    text-align: left;
    /* border-radius: 25px; */
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4A4A4A;


    /* Rounded corners for cells */
}


.dynamic-table-data tbody tr {
    border-bottom: 10px solid #e0e0e0;
}


.dynamic-table-data tbody tr:hover {
    background-color: #e0e0e0;
    transition: background-color 0.2s ease;
}

.name {
    display: flex;
    align-items: center;
}


.button-icon {
    border: none;
    outline: none;
    background-color: #FFFFFF;
}


.change-team {
    background-color: #E4DDFC;
    width: 60px;
    height: 29.21px;
    border-radius: 9px;
    padding: 10px;

}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 45px 10px 35px;
}

.candidateEditForm {
    padding: 20px;
    background: #f2f2f2;
    flex-grow: 1;
    font-family: "Poppins";
    display: flex;
    font-weight: 600;
    max-height: 87%;
    box-sizing: border-box;
    flex-direction: column;


    .header {
        display: flex;
        align-items: center;
        gap: 10px;

        :nth-child(1) {
            margin-right: 10px;
        }

        .title {
            font-size: 20px;
            color: #1C1C63;
        }
    }

    .mainContent {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .legendHeading {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #5390C2;
        padding-bottom: 10px;

        img {
            border-radius: 50%;
            padding: 5px;
            background-color: #48A9f8;
        }

        .divider {
            width: 100%;
            border-bottom: 1px solid #C9C9C9;
        }

        .divider1 {
            width: 85%;
            border-bottom: 1px solid #C9C9C9;
        }

    }

}



.inputComponent {
    display: flex;
    gap: 5px;
    flex-direction: column;
    font-weight: 400;
    border-bottom: 1px solid #BDBDBD;

    label {
        font-size: 13px;
        color: #909090;
    }

    input,
    select {
        display: block;
        color: #bdbdbd;
        width: 100%;
        border: none;
        font-size: 15px;
        font-weight: 600;
        background-color: transparent;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        color: #494E63;
        border: none;
        outline: none;
        /* &:focus {
            border: none;
            outline: none;
            font-style: bold;
            color: black;
        } */
    }

}


.filters-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 45px 10px 35px;
}

.filters-left-data {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    /* for responsiveness */
    background-color: #F2F2F2;


    input,
    select {
        width: 20%;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #858484;
        border: 0.1px solid #D0CFCF;
        background-color: #EDEDED;
        border-radius: 10px;
    }
}

.filters-right-data {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #F2F2F2;

    div {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #858484;
        border: 0.1px solid #D0CFCF;
        background-color: #EDEDED;
        border-radius: 10px;
        padding: 5px 10px;
        /* width: 100px; */
    }

    button {
        width: 30px;
        height: 30px;
        border-radius: 5px 5px 5px 5px;
        background: #434343;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.team-col {
    width: 120px;
}

.org-col {
    width: 300px;
}

.act-col {
    width: 120px;
}


/* Media Query */
@media only screen and (min-width: 360px) and (max-width: 481px) {
    .top-section {
        padding: 5px 5px;
    }

    .title-left {
        font-size: 12px;
    }

    
}

@media only screen and (min-width: 482px) and (max-width: 768px) {

}

@media only screen and (min-width: 769px) and (max-width: 1100px) {}