/* Ensure container is always present */
.overlay-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 25%;
    height: 100%;
    z-index: 11;
    pointer-events: none; /* Prevents accidental clicks when hidden */
  }
  
  /* Background Overlay */
  .overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 11;
  }
  
  /* Sliding Panel */
  .panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 35%;
    height: 100%;
    background: white;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    transform: translateX(100%); /* Initially hidden */
    transition: transform 0.2s ease-in;
    z-index: 11;
    overflow-x: auto;
    scrollbar-width: none; 
  }

  .panel::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari, Edge */
  }
  
  /* Apply transform when open */
  .panel.open {
    transform: translateX(0);
  }
  
  /* Show overlay container when active */
  .overlay-container.visible {
    pointer-events: auto;
  }