@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

.w-100 {
  width: 100%;
  background-color: #f2f2f2;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;

  button {
    top: 13.24px;
    left: 1278.98px;
    gap: 0px;
  }
}

.title-left {
  font-size: 18px;
  font-weight: bold;
  color: #15649c;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  flex-grow: 1;
  padding-left: 10px;
}

.profile-button-right {
  display: flex;
  width: 131.44px;
  height: 36.51px;
  top: 13.24px;
  left: 1278.98px;
  gap: 0px;
  border-radius: 38px;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  color: #5e7ebd;
  background: linear-gradient(90deg, rgba(213, 223, 242, 0.5) 0%, rgba(206, 196, 232, 0.5) 100%);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile {
  padding-right: 5px;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;
  /* gap: 20px; */

  .col-span-2 {
    grid-column: 1 / 3;
  }

  .col-span-3 {
    grid-column: 1 / 4;
  }
}

.grid-insider-one {
  display: flex;
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #f0f5ff;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  /* background-color: red; */
}

.dashCards {
  margin: unset !important;
  padding: 16px !important;
  width: calc(33% - 24px) !important;
  min-width: 300px;
  max-width: 500px;
  height: unset !important;
  border-radius: 30px 30px 30px 30px !important;
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 3px 20px rgba(9, 30, 66, 0.1), 0px 0px 2px rgba(9, 30, 66, 0.31);
}
.grid-insider-one {
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #f0f5ff;
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}
.rAnalysisDash {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  box-sizing: border-box;
  padding: 0 30px;
  margin: 10px 0;
}

.grid-insider-one:hover {
  background-color: #e0e0ff;
  transform: scale(1.02);
  border: 1px solid #e0e0ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
}

.total-assig-one,
.number-item-one,
.all-time-one,
.side-icon-one {
  pointer-events: none;
}

.grid-insider-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #fffdf0;
  /* background-color: red; */
  position: relative;
  /* Ensures proper positioning */
  /* Initial background color */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth hover transitions */
  cursor: pointer;
  box-sizing: border-box;

  /* Show pointer on hover */
}

.grid-insider-two:hover {
  background-color: #fffcd5;
  /* Example hover background color */
  transform: scale(1.02);
  /* Slight scaling effect on hover */
  border: 1px solid #fffcd5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
}

.total-assig-two,
.number-item-two,
.all-time-two,
.side-icon-two {
  pointer-events: none;
  /* Ensures hover applies only to the parent */
}

.grid-insider-three {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #f0fff8;
  position: relative;
  /* Ensures proper positioning */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth hover effects */
  cursor: pointer;
  box-sizing: border-box;

  /* Changes cursor on hover */
}

.grid-insider-three:hover {
  background-color: #d7f6e4;
  /* Light green hover background */
  transform: scale(1.02);
  /* Slight scaling effect */
  border: 1px solid #d7f6e4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
}

.total-assig-three,
.number-item-three,
.all-time-three,
.side-icon-three {
  pointer-events: none;
  /* Ensures hover applies only to the parent */
}

.grid-insider-four {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #fff6f0;
  position: relative;
  /* Ensures proper positioning */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth hover effects */
  cursor: pointer;
  box-sizing: border-box;

  /* Changes cursor on hover */
  /* background-color: red; */
}

.grid-insider-four:hover {
  background-color: #ffe6d9;
  /* Light orange hover background */
  transform: scale(1.02);
  /* Slight scaling effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
  border: 1px solid #ffe6d9;
}

.total-assig-four,
.number-item-four,
.all-time-four,
.side-icon-four {
  pointer-events: none;
  /* Prevent hover interaction with child elements */
}

.grid-insider-five {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #f4fff0;
  position: relative;
  /* Ensures proper positioning */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth hover effects */
  cursor: pointer;
  box-sizing: border-box;

  /* Changes cursor on hover */
}

.grid-insider-five:hover {
  background-color: #e5ffe2;
  /* Light green hover background */
  transform: scale(1.02);
  /* Slight scaling effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
  border: 1px solid #e5ffe2;
}

.total-assig-five,
.number-item-five,
.all-time-five,
.side-icon-five {
  pointer-events: none;
  /* Ensures hover applies only to the parent */
}

.grid-insider-six {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  padding: 10px;
  width: 356.6px;
  height: 113.37px;
  border-radius: 30px 30px 30px 30px;
  background-color: #f0f9ff;
  box-sizing: border-box;
  /* background-color: red; */
  position: relative;
  /* Ensures proper positioning */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth hover effects */
  cursor: pointer;
  /* Changes cursor on hover */
}

.grid-insider-six:hover {
  background-color: #e0f3ff;
  /* Light blue hover background */
  transform: scale(1.02);
  /* Slight scaling effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Enhanced shadow on hover */
  border: 1px solid #e0f3ff;
}

.total-assig-six,
.number-item-six,
.all-time-six,
.side-icon-six {
  pointer-events: none;
  /* Prevents hover/click events from affecting child elements */
}

.total-assig-one {
  padding: 10px;
  width: 219.97px;
  height: 44.52px;
  color: #312fa3;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.total-assig-two {
  padding: 10px;
  width: 219.97px;
  height: 44.52px;
  color: #c19324;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.total-assig-three {
  padding: 10px;
  width: 219.97px;
  height: 44.52px;
  color: #1cb774;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.total-assig-four {
  padding: 10px;
  width: 219.97px;
  height: 44.52px;
  color: #c8623c;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.total-assig-five {
  padding: 10px;
  width: 219.97px;
  height: 44.52px;
  color: #60c124;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.total-assig-six {
  padding: 10px;
  width: 219.97px;
  height: 44.52px;
  color: #289ebe;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.number-item-one {
  /* width: 36.84px; */
  height: 44.52px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #312fa3;
}

.number-item-two {
  /* width: 36.84px; */
  height: 44.52px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #c19324;
}

.number-item-three {
  /* width: 36.84px; */
  height: 44.52px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #1cb774;
}

.number-item-four {
  /* width: 36.84px; */
  height: 44.52px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #c8623c;
}

.number-item-five {
  /* width: 36.84px; */
  height: 44.52px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #60c124;
}

.number-item-six {
  /* width: 36.84px; */
  height: 44.52px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #289ebe;
}

.all-time-one {
  width: 67px;
  height: 21px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #312fa3;
}

.all-time-two {
  width: 67px;
  height: 21px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #c19324;
}

.all-time-three {
  width: 67px;
  height: 21px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #1cb774;
}

.all-time-four {
  /* width: 67px; */
  height: 21px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #c8623c;
}

.all-time-five {
  width: 67px;
  height: 21px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #60c124;
}

.all-time-six {
  /* width: 67px; */
  height: 21px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #289ebe;
}

.row-flex-data {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.side-icon-one {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 10%;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  background-color: rgba(130, 128, 255, 0.21);
  border-radius: 21px;
}

.side-icon-two {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 10%;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  /* background-color: rgba(130, 128, 255, 0.21); */
  background: rgba(254, 197, 61, 0.21);
  border-radius: 21px;
}

.side-icon-three {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 10%;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  /* background-color: rgba(130, 128, 255, 0.21); */
  /* background: rgba(254, 197, 61, 0.21); */
  background: rgba(74, 217, 145, 0.21);

  border-radius: 21px;
}

.side-icon-four {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 10%;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  /* background-color: rgba(130, 128, 255, 0.21); */
  /* background: rgba(254, 197, 61, 0.21); */
  background: rgba(255, 144, 102, 0.21);

  border-radius: 21px;
}

.side-icon-five {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 10%;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  /* background-color: rgba(130, 128, 255, 0.21); */
  /* background: rgba(254, 197, 61, 0.21); */
  background: rgba(151, 254, 61, 0.21);

  border-radius: 21px;
}

.side-icon-six {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 10%;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  /* background-color: rgba(130, 128, 255, 0.21); */
  /* background: rgba(254, 197, 61, 0.21); */
  background: rgba(102, 204, 255, 0.21);

  border-radius: 21px;
}

/* for analysis details */

.sidebar-nav {
  padding: 10px;
  background-color: #ffffff;
  border-left: 0.3px solid #b9b9b9;
  /* height: 100vh; */
  width: 302.15px;
  height: 840px;
}

.icon-back {
  display: flex;
  align-items: center;
  padding-right: 6px;
  padding-left: 2px;
}

.back-to-dash {
  display: flex;
  align-items: center;
  width: 178.85px;
  height: 34.26px;
  border-radius: 10px 10px 10px 10px;
  /* background-color: rgba(72, 128, 255, .1); */
  background-color: rgba(103, 93, 193, 0.1);
  font-family: Poppins;
  font-size: 14.6px;
  font-weight: 400;
  line-height: 21.9px;
  text-align: left;
  /* color: rgba(0, 124, 178, 1); */
  color: rgba(69, 67, 172, 1);
}

.back-to-dash:hover {
  cursor: pointer;
}

/* in case of active */

.inner-nav-data {
  /* padding-left: 10px; */
  align-items: center;
  margin-top: 15px;
  width: 249.82px;
  /* height: 76.3px; */
  border-radius: 16px 16px 16px 16px;
  border: 1px 0px 0px 0px;
  opacity: 0.13px;
  /* background-color: rgba(72, 128, 255, .1); */
  /* background-color: rgba(156, 156, 156, .1); */
  /* background-color: rgba(69, 67, 172, .1); */
  background-color: rgba(103, 93, 193, 0.1);

  /* box-shadow: 0px 4px 30px 0px rgba(60, 61, 97, 0.61); */
}

.inner-nav {
  /* padding-left: 10px; */
  align-items: center;
  margin-top: 15px;
  width: 249.82px;
  height: 76.3px;
  border-radius: 16px 16px 16px 16px;
  border: 1px 0px 0px 0px;
  opacity: 0.13px;
  /* background-color: rgba(72, 128, 255, .1); */
  /* background-color: rgba(156, 156, 156, .1); */
  /* background-color: rgba(69, 67, 172, .1); */
  background-color: rgba(103, 93, 193, 0.1);

  svg {
    fill: rgba(69, 67, 172, 1);
  }

  /* box-shadow: 0px 4px 30px 0px rgba(60, 61, 97, 0.61); */
}

.inner-nav.active-tab {
  /* background-color: rgba(72, 128, 255, .1); */
  background-color: rgba(69, 67, 172, 1);

  /* color: white; */
  .tabs-name {
    width: 100%;
    /* color: rgba(72, 128, 255, 1); */
    color: rgba(255, 255, 255, 1);
  }

  .number-item-one-details {
    /* color: rgba(72, 128, 255, 1); */

    color: rgba(255, 255, 255, 1);
  }

  .all-time-one-details {
    /* color: rgba(72, 128, 255, 1); */
    color: rgba(255, 255, 255, 1);
  }
  /* color: rgb(72, 128, 255); */
  /* Optional: change text color for better contrast */
  svg {
    fill: rgba(255, 255, 255, 1);
    /* fill: white; */
  }
}

.tabs-name {
  padding: 10px 10px 5px 10px;
  width: 100%;
  margin-bottom: 20px;
  /* height: 19px; */
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  /* color: #4880FF; */
  color: rgba(69, 67, 172, 1);
}

.icon-one {
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex-direction: row;
}

.row-flex-data1 {
  display: flex;
  align-items: center;
}

.number-item-one-details {
  width: 106px;
  height: 25px;
  top: 180.69px;
  left: 243.85px;
  gap: 0px;
  opacity: 0.6px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.55px;
  text-align: right;
  /* color: #2860DF; */
  /* color: rgba(69, 69, 69, 1); */
  color: rgba(69, 67, 172, 1);
}

.all-time-one-details {
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  /* color: #2860DF; */
  /* color: rgba(69, 69, 69, 1); */
  color: rgba(69, 67, 172, 1);
}

.inner-benchData-details {
  display: flex;
  flex-direction: row;
  nav {
  }

  ul {
    list-style: none;
  }
}

.candidate-info-data-details {
  /* display: flex; */
  flex-direction: row;
  /* background-color: red; */
  flex-grow: 1;
}

.mainContent-data {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.table-container-data {
  width: 100%;
  overflow-x: auto;
  /* padding: 20px; */
}

.dynamic-table-data1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  text-align: left;
  background-color: #f2f2f2;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 10px;
  border-spacing: 30px;
  border-collapse: separate;
  border-spacing: 0 15px;

  select {
    border-radius: 10px;
  }

  .alignCenter {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.dynamic-table-data1 thead th {
  background-color: #ededed;
  padding: 10px;
  font-weight: bold;
  color: #4a4a4a;
  text-align: left;
  /* display: flex;
    align-items: center; */
}

.dynamic-table-data1 tbody tr {
  padding: 10px;

  td:first-child {
    border-radius: 13px 0 0px 13px;
  }

  td:last-child {
    border-radius: 0 13px 13px 0;
  }
}

.dynamic-table-data1 tbody td {
  background-color: #ffffff;
  padding: 10px;
  /* border-bottom: 10px solid #f7f7f7; */
  text-align: left;
  /* border-radius: 25px; */
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4a4a4a;
  /* Rounded corners for cells */
}

.dynamic-table-data1 tbody tr {
  border-bottom: 10px solid #e0e0e0;
}

.dynamic-table-data1 tbody tr:hover {
  background-color: #e0e0e0;
  transition: background-color 0.2s ease;
}

/* .overlaypopup {
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    z-index: 9999;
    padding-top: 20%;
    padding-left: 30%;
    background-color: rgba(96, 193, 36,.1);
} */

.overlaypopup {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 20%;
    padding-left: 30%; */
  background-color: rgba(96, 193, 36, 0.1);
  /* Optional semi-transparent background for the dropdown */
}

.back-to-dropdown {
  position: fixed;
  /* Keeps the button fixed in the viewport */
  bottom: 70px;
  /* Distance from the bottom */
  left: 54%;
  /* Centers horizontally */
  transform: translateX(-50%);
  /* Centers the button perfectly */
  padding: 10px 20px;
  background-color: #15649c;
  /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9999;
  /* Makes sure it's above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: none;
  /* Ensure no transition is applied */
}

.back-to-dropdown:hover {
  background-color: #6193c9;
  /* Darker shade on hover */
}

.drop-down.analysis {
  &:before {
    right: 14px;
    position: absolute;
  }
}
.drop-down {
  /* width: 42%; */
  height: 10%;
  position: relative;
  display: flex;
  /* padding-left: 40%;
    padding-top: 20px; */
  &:before {
    content: "\25BC";
    /* position: absolute; */
    pointer-events: none;
    /* display: block; */
    /* width: 100%; */
    /* height: 100%; */
    /* transform: scale(.5); */
    font-size: 14px;
    /* padding-top: 7px; */
    /* padding-left: 5px; */
    transform: translate(25px, 5px);
  }
}

.recruiter-dropdown select {
  width: 100%;
  padding-left: 0%;
}

.select-data {
  appearance: none;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid black;
  padding: 5px;
  padding-left: 30px;
  /* padding-top: 0px; */
  width: 350px;
  /* height: 100%; */
  /* background-color: rgba(255, 255, 255, .1); */
  /* border-radius: 5px; */
  /* border: 2px solid rgba(0, 0, 0, .1); */
  /* margin-top: -100px; */
  border: none;
  border-radius: 20px;
}

.select-data-rec {
  appearance: none;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid black;
  padding: 5px;
  padding-left: 30px;
  /* padding-top: 0px; */
  width: 30px;
  /* height: 100%; */
  /* background-color: rgba(255, 255, 255, .1); */
  /* border-radius: 5px; */
  /* border: 2px solid rgba(0, 0, 0, .1); */
  /* margin-top: -100px; */
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.select-data-rec:focus {
  border: none;
  /* outline: 1px solid #4a3c87; */
}

.select-data:focus {
  border: none;
  outline: 1px solid #4a3c87;
}

.select-data:hover {
  /* background-color: rgba(224, 224, 255, .21); */
  /* transform: scale(1.02); */
  /* border: 1px solid #e0e0ff; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* Enhanced shadow on hover */
}

.left-subtable-section .navigation .text {
  font-size: 14px;
}

.left-subtable-section .navigation {
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.left-subtable-section .nav {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-subtable-section .left.nav svg {
  rotate: 180deg;
}

.table-container-data .navigation .text {
  font-size: 14px;
}

.table-container-data .navigation {
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.table-container-data .nav {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container-data .left.nav svg {
  rotate: 180deg;
}

.scroll-bar-data {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  height: 100vh;
  scrollbar-width: thin;
}

.scroll-bar-data::-webkit-scrollbar {
  height: 8px;
}

.scroll-bar-data::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scroll-bar-data::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* css for pop-up */

.change-color:hover {
  background-color: rgba(69, 67, 172, 1);
  color: white;
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;
}

/* Highlight the clicked row */
.highlighted-row td .change-color {
  background-color: rgba(69, 67, 172, 1) !important; /* Light highlight */
  color: white !important; /* Text color for contrast */
}

/* Optional: Add some transition for smoother effect */
.highlighted-row td {
  /* transition: background-color 0.3s ease, color 0.3s ease; */
}

.text-auto {
  text-wrap: auto;
}

.table-row {
  background-color: transparent;
  color: inherit;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.table-row:hover {
  background-color: rgba(69, 67, 172, 1);
  color: white;
  cursor: pointer;
}

/* sub table */

.container-wrapper {
  padding-left: 10px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  /* Ensures both sections are aligned on top */
  margin-top: 20px;
}

.left-subtable-section {
  flex: 1;
  /* Takes up remaining space */
}

.left-dynamic-subtable {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  text-align: left;
  background-color: #f2f2f2;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  /* padding: 10px; */
  border-spacing: 30px;
  border-collapse: separate;
  border-spacing: 0 15px;
  overflow: hidden;
}

.left-dynamic-subtable th {
  /* background-color: #e3d7f5; */
  background-color: rgba(69, 67, 172, 0.9);
  color: rgba(255, 255, 255, 1);
  padding: 6px;
  font-weight: bold;
  text-align: left;
  /* padding: 10px; */
}

.left-dynamic-subtable tr {
  padding: 8px;

  td:first-child {
    border-radius: 13px 0 0px 13px;
  }

  td:last-child {
    border-radius: 0 13px 13px 0;
  }
}

.left-dynamic-subtable td {
  background-color: #ffffff;
  padding: 6px;
  /* border-bottom: 10px solid #f7f7f7; */
  text-align: left;
  /* border-radius: 25px; */
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4a4a4a;
}

.header-align-center {
  display: flex;
  /* align-items: center;
    justify-content: center; */
  font-weight: bold;
}

.right-submit-reason {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
}

.submit-reason-header {
  color: #4a3c87;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 18px;
}

.textarea-reason-input {
  width: 70%;
  height: 100%;
  padding: 10px;
  border: 1px solid #d3c7f3;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 5px;
  font-family: "Arial", sans-serif;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: none;
}

.textarea-reason-input::placeholder {
  color: #8c86a1;
  font-style: italic;
}

.button-submit-reason {
  /* background-color: #4a3c87; */
  background-color: rgba(69, 67, 172, 1);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 32px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.button-submit-reason:hover {
  background-color: #362a6b;
  transform: translateY(-2px);
}

.button-submit-reason:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vertical-line {
  width: 1px;
  border-left: 2px dashed #4a3c87;
  /* Dotted/Dashed Line */
  height: 190px;
  /* Height of the line */
  background-color: black;
  margin-top: 14px;
}

.choose-rec {
  display: flex;
  flex-direction: row; /* Makes items stack vertically */
  padding: 20px;
  width: 100%;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  color: rgba(69, 67, 172, 1);
  gap: 10px; /* Adds spacing between rows */
}

.icon-top {
  background-color: rgba(69, 67, 172, 1);
  width: 29px;
  height: 29px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
}

.choose {
  width: 19ch;
  padding-left: 4px;
  /* padding-right: 4px; */
  font-size: 18px; /* Adjust font size */
}

.errow-icon {
  cursor: pointer;
  color: rgba(69, 67, 172, 1);
}

.line {
  /* Adds space before the line */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.line svg {
  width: 150%; /* Makes the line span the container width */
}

.toast-center {
  /* Makes the element fixed relative to the viewport */
  /* top: 22rem;
    width: 25rem;
    height: 12rem;
    left: 40%; */
  border: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 1700px) {
  .dashCards {
    margin: unset !important;
    padding: 16px !important;
    width: calc(25% - 24px) !important;
    min-width: 350px;
    max-width: unset;
  }
}

/* media query */

@media only screen and (min-width: 600px) and (max-width: 952px) {
  .vertical-line {
  }
}

@media only screen and (min-width: 953px) and (max-width: 1100px) {
}

@media only screen and (min-width: 1101px) and (max-width: 1400px) {
}
