@import url('./common.css');
@import url('./mediaQueries.css');
* {
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: var(--font-primary);
font-style: var(--font-style);
}

:root{

    /* border-radius */

    --br-none : 0px;
    --br-xs : 4px;
    --br-sm: 6px;
    --br-md: 10px;
    --br-lg: 12px;
    --br-xl: 14px;
    --br-2xl: 16px;
    --br-3xl: 20px;
    --br-half: 50%;
    --br-full: 100%;

    /* font-size */

    --fs-xs: 8px;
    --fs-sm: 12px;
    --fs-md: 16px;
    --fs-lg: 20px;
    --fs-xl: 24px;
    --fs-2xl: 28px;
    --fs-3xl: 32px;

    --font-primary: "Poppins", sans-serif;
    --font-style: normal;

    --font-weight-thin: 100;
    --font-weight-extralight : 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;

    --clr-main: #330867;
    --clr-primary: #6115BE;
    --clr-secondary: #F3F2F2;
    --clr-teritary: #8E8E8E;
    --clr-grey: #C7CECF;
    --clr-white: #ffffff;
    --clr-black: #000000;
    --clr-warning: rgb(255, 42, 0);

}

.v2-btn{
    color: white;
    border: none;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px;
    background: linear-gradient(90deg, rgba(80, 119, 161, 1.00) 0%, #330867 100%);
}

.v2-heading{
    color: var(--clr-main);
    font-weight: 500;
    font-size: 20px;
}