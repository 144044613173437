@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("./assets/styles/main.css");
* {
  font-family: "Poppins", sans-serif;
  /* transition: all 0.9s; */
}
* .row-flex {
  display: flex;
  flex-direction: row;
}
* .col-flex {
  display: flex;
  flex-direction: column;
}
.clamp span.value a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clamp span.value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.full-width-div {
  width: 100%;
}

input {
  background-color: transparent;
}

.wrap-content {
  overflow-wrap: anywhere;
  word-break: break-word;
  hyphens: auto;
}
