.custom-select{
    width: 100%;
    position: relative;
}
.custom-select-btn{
    padding: 4px;
    background-color: transparent;
    cursor: pointer;
    border: solid 0.5px var(--clr-grey);
    border-radius: 5px;
    width: inherit;
}
.selected-dropdown {
    position: absolute;  
    top: 100%;  
    left: 0;  
    z-index: 5;  
    background: white;  
    border: solid 0.5px var(--clr-grey);
    border-radius: 5px;
    list-style-type: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    max-height: 200px;
    overflow-y: auto;
    width: max-content;
    min-width: 100%;
    margin-top: 5px;
}

.selected-dropdown li{
    padding: 10px;
    border: solid var(--clr-grey);
    border-width: 0px 0px 0.5px 0px;
    font-size: 13px;
    cursor: pointer;
}

.selected-dropdown li.selected{
    background-color: #f2f2f2;
    border-radius: 4px;
    font-weight: bold;
}


.selected-dropdown li:hover{
    background-color: var(--clr-secondary);
}

.arrow{
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid var(--clr-teritary);;
    transition: transform ease-in-out 0.3s;
}


.hidden{
    display: none !important;
}

.selected{
    color: var(--clr-teritary);
}